import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "api-keys-in-autopatcher"
    }}>{`API keys in AutoPatcher`}</h1>
    <p>{`AutoPatcher allows privileged Users to create and manage API keys, which can be used as an alternative authentication method for AutoPatcher CLI or to access AutoPatcher GraphQL API.`}</p>
    <p>{`API keys can be browsed and managed in the `}<inlineCode parentName="p">{`API keys`}</inlineCode>{` view`}</p>
    <p><img alt="API keys view" src={require("../assets/managing-api-keys/navigation-item.png")} /></p>
    <p>{`To create a new API key, click the `}<inlineCode parentName="p">{`New API key`}</inlineCode>{` button. `}</p>
    <p><img alt="API keys listing" src={require("../assets/managing-api-keys/listing.png")} /></p>
    <p>{`After specifying all properties, click `}<inlineCode parentName="p">{`Create`}</inlineCode>{` to complete.`}</p>
    <p><img alt="API keys creation form" src={require("../assets/managing-api-keys/creating.png")} /></p>
    <p>{`Once the API key is created, a modal with its secret is displayed. You can click the `}<inlineCode parentName="p">{`Copy API key`}</inlineCode>{` button to copy the secret to clipboard. Make sure you save the API key secret, because you cannot recover it later. `}</p>
    <p><img alt="API keys creation modal" src={require("../assets/managing-api-keys/secret-modal.png")} /></p>
    <p>{`Click the selected API key from the listing to browse its details. To edit the API key, click the `}<inlineCode parentName="p">{`Edit`}</inlineCode>{` button.`}</p>
    <p><img alt="API key details" src={require("../assets/managing-api-keys/details.png")} /></p>
    <p>{`Permissions for API keys are granted by creating Assignments to Roles. All Assignments for selected API key are listed in its details view. Managing permissions in AutoPatcher is described in more detail in the `}<a parentName="p" {...{
        "href": "https://autopatcher.nordcloudapp.com/managing-api-keys"
      }}>{`Managing permissions`}</a>{` section.`}</p>
    <p>{`To create a new Assignment for API key, you can click `}<inlineCode parentName="p">{`New Assignment`}</inlineCode>{`.
It will open the form for creating Assignment with prefilled `}<inlineCode parentName="p">{`Type`}</inlineCode>{` and `}<inlineCode parentName="p">{`Subject`}</inlineCode>{` fields for selected API key.`}</p>
    <p><img alt="API key new assignment" src={require("../assets/managing-api-keys/creating-assignment.png")} /></p>
    <p>{`Alternatively, you can go to the `}<inlineCode parentName="p">{`Permissions view`}</inlineCode>{`, click `}<inlineCode parentName="p">{`New Assignment`}</inlineCode>{` and fill in the fields accordingly.`}</p>
    <p>{`To delete API key and all its Assignments, click the button in the API key listing and confirm the operation.`}</p>
    <p><img alt="API key deleting" src={require("../assets/managing-api-keys/deleting.png")} />{`
`}<img alt="API key deleting confirmation" src={require("../assets/managing-api-keys/deleting-confirmation.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      